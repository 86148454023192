import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  slides : Array<any> = [];
  itemsPerSlide = 3;
  singleSlideOffset = false;
  noWrap = false;

  constructor() { 
    this.slides = [
      {
        image : 'assets/images/decoupes/ma-banque.png'
      },
      {
        image : 'assets/images/decoupes/ma-banque.png'
      },
      {
        image : 'assets/images/decoupes/ma-banque.png'
      },
      {
        image : 'assets/images/decoupes/ma-banque.png'
      },
      {
        image : 'assets/images/decoupes/ma-banque.png'
      },
      {
        image : 'assets/images/decoupes/ma-banque.png'
      },
      {
        image : 'assets/images/decoupes/ma-banque.png'
      }
    ]
  }


  onSlideRangeChange(indexes: number[]): void {
    //this.slidesChangeMessage = `Slides have been switched: ${indexes}`;
  }

  ngOnInit() {
  }

}
