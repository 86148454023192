import { Component, OnInit } from '@angular/core';
import { ApiService } from '../services/api.service';
import { Router } from '@angular/router';
import { UtilitiesService } from '../services/utilities.service';
import { UserService } from '../services/user.service';
import { NotificationService } from '../services/notification.service';
// const swal = require('sweetalert2');
import swal from 'sweetalert2';
// declare var swal: any;
import * as moment from 'moment';
// import { moment } from 'ngx-bootstrap/chronos/test/chain';

@Component({
  selector: 'app-form-collecte',
  templateUrl: './form-collecte.component.html',
  styleUrls: ['./form-collecte.component.scss']
})
export class FormCollecteComponent implements OnInit {

  itemToSave : any = {
    dateStatistique : new Date() 
  };
  user : any = {};
  ListObservations : Array<any> = [];

  constructor(private api:ApiService,public router:Router,private utilities : UtilitiesService,private userService : UserService,private notificationService : NotificationService) { }

  maxDate = new Date();

  ngOnInit() {
    this.user = this.userService.getCurrentUser();
    if(!this.user)
    {
      this.router.navigate(['/auth']);
    }
  }

  addObservation(libelleObser)
  {
    if(libelleObser)
    {
      let isExist = this.ListObservations.find(element => element.libelle == libelleObser);

      if(!isExist)
      {
        this.ListObservations.push({
          libelle : libelleObser
        });
        this.itemToSave.observation = null;
      }
      else
      {
         this.notificationService.showMessageError("Erreur","Ce libellé existe déjà.");
      }
    }
    else
    {
      this.notificationService.showMessageError("Erreur","Veuillez renseigner le libellé de l'observation.");
    }
  }

  removeItem(indice)
  {
    this.ListObservations.splice(indice,1);
  }

  confirmSave(itemToSave) {

    console.log("le item to save ",itemToSave);
    let item = Object.assign({}, itemToSave);
    if (!item || !item.dateStatistique || !item.dureeMoyenEnrollement) {
      this.notificationService.showMessageError("Information", "Veuillez renseigner les champs obligatoires.")
      return;
    }

    item.dateStatistique = moment(itemToSave.dateStatistique).format("DD/MM/YYYY");
    item.observations = this.ListObservations || [];
    
    swal({
      title:'Enregistrer des statistiques',
      text: "<span style='font-size:17px'> Désirez-vous poursuivre cette action ? </span>",
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      confirmButtonColor: "#ff7512",
      cancelButtonColor: "rgb(154, 154, 153)",
    }).then((result) => {
      if (result) {
         this.saveItem(item);
      } else {
        // console.log("bnjr");
      }
    });
  }

  saveItem(item) {
    this.itemToSave.isClick = true;
    var request = {
      user: this.user.proxy,
      datas: [
        item
      ]
    }

    this.api.post('statistiquesOneci/' + (item.id ? 'update' : 'create'), request)
      .subscribe(
        res => {
          console.log("resul", res);
          this.itemToSave.isClick = false;
          if (!res['hasError'] && res['items']) {
            this.notificationService.showMessageSucces("Statistiques", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            this.itemToSave = {};
            this.ListObservations = [];
          } else {
            if (res['status'] && res['status']['message']) {
              this.notificationService.showMessageError("Erreur", this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
            }
          }
        },
        err => {
          this.notificationService.showMessageError("Conexion", this.utilities.defautMessage());
        }
      );
  }


}
