import { Component, OnInit } from '@angular/core';
import { FormReconnectComponent } from '../../form-reconnect/form-reconnect.component';
import { ActivatedRoute, Router } from '@angular/router';

import { UtilitiesService } from '../../services/utilities.service';
import { UserService } from '../../services/user.service';
import { NotificationService } from '../../services/notification.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent implements OnInit {

  user : any;
  deconnectionTimer : number;
  bsModalRef : BsModalRef;
  intervalle = 1000;
  countTer = 0;
  activite_detectee : boolean; 
  logoutModalIsLunch : boolean = false;
  lunchFonction : any;

  config = {
    animated: true,
    keyboard: false,
    backdrop: 'static',
    ignoreBackdropClick: true
  };


  constructor(private activedRoute: ActivatedRoute,private userService: UserService,public router:Router,private api:ApiService,private modalService: BsModalService) {
   
    this.user = this.userService.getCurrentUser(); 
    this.deconnectionTimer = (this.user.deconnectionTimer ? this.user.deconnectionTimer : this.deconnectionTimer) * 60000;
    this.initTimoutActivityScreem(); 
  }

  initTimoutActivityScreem()
  {
    this.lunchFonction = setTimeout(() => {
      this.getActiviteInScream()
    }, 500);
  }

  reinitActive()
  {
     console.log("reinit value");
    this.activite_detectee = true;
  }

  openPasswordForm() {
    this.logoutModalIsLunch = true;
    this.bsModalRef = this.modalService.show(FormReconnectComponent, Object.assign({}, this.config, { class: 'gray  modal-login-reconnect' }));
    this.bsModalRef.content.currentUser = this.user;
    let refreshTimout = this.modalService.onHide.subscribe(r=>{
      this.countTer = 0;
      this.logoutModalIsLunch = false;
      window.clearTimeout(this.lunchFonction);
      this.initTimoutActivityScreem();
    });
  }

    // On crée la fonction qui teste toutes les x secondes l'activité du visiteur via activite_detectee
    getActiviteInScream() {
     
      if(this.activite_detectee)
      {
            this.activite_detectee = false; 
            this.countTer = 0;
      }
      else
      {

            let href = this.router.url;
            // console.log("href ",href)
            // if(href == '/auth/login')
            // {
            //   this.countTer = 0;
            //   return;
            // }

            this.countTer = this.countTer + 1000;
            console.log("le active route",this.countTer);

           
            // console.log("le counter component ts",this.countTer); 
            if(this.countTer == this.deconnectionTimer)
            {
                if(this.logoutModalIsLunch)
                {
                  this.countTer = 0;
                  window.clearTimeout(this.lunchFonction);
                  this.logoutModalIsLunch = false;
                  return;
                }
               
               // Deconnecter l'utilisateur
                
                this.openPasswordForm();
                localStorage.removeItem("yz-backoffice-app-collecte-oneci");
                //this.router.navigate(['/auth']);
               
                //document.getElementById('click-btn').click();
                window.clearTimeout(this.lunchFonction);
                this.countTer = 0;
                return;
            }
            else
            {
              if(this.logoutModalIsLunch)
              {
                this.countTer = 0;
                window.clearTimeout(this.lunchFonction);
                this.logoutModalIsLunch = false;
                return;
              }
            }
      }
      
      this.lunchFonction = setTimeout(() => {
         this.getActiviteInScream()
      }, 1000);
 
  }

  ngOnInit() {
    
  }

  ngOnDestroy(){
    window.clearTimeout(this.lunchFonction);
  }


}
