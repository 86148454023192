import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainLayoutComponent } from './shared/main-layout/main-layout.component';
import { HomeComponent } from './home/home.component';
import { AuthLayoutComponent } from './shared/auth-layout/auth-layout.component';
import { MainAuthentificationComponent } from './main-authentification/main-authentification.component';
import { FormCollecteComponent } from './form-collecte/form-collecte.component';
import { MainCollecteDonneComponent } from './main-collecte-donne/main-collecte-donne.component';

const routes: Routes = [
  {
    path: '', redirectTo: 'auth', pathMatch: 'full',
  },
  {
    path: '',
    component: MainLayoutComponent,
    data: {pageTitle: 'Accueil'},
    children: [
      {
        path: '', redirectTo: 'home', pathMatch: 'full',
      },
      {path: 'home', component: HomeComponent,data:{pageTitle: ''}},
      {
        path :'collecte',component:FormCollecteComponent,data : {pageTitle : 'Collecte de donnée'}
      },
      {
        path :'collecte-donnee',component:MainCollecteDonneComponent,data : {pageTitle : 'Collecte de donnée'}
      }
    ]
  },

  {
    path: 'auth',
    component: AuthLayoutComponent,
    data: {pageTitle: 'Authentification'},
    children: [
      {
        path: '', redirectTo: 'login', pathMatch: 'full',
      },
      {path: 'login', component: MainAuthentificationComponent,data:{pageTitle: ''}}
    ]
  },

  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
