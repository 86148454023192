import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../services/api.service';
import { Router } from '@angular/router';
import { UtilitiesService } from '../services/utilities.service';
import { UserService } from '../services/user.service';
import { NotificationService } from '../services/notification.service';
// const swal = require('sweetalert2');
import swal from 'sweetalert2';
// declare var swal: any;
import * as moment from 'moment';
// import { BsModalRef } from 'ngx-bootstrap/modal/ngx-bootstrap-modal';
import { Subscription } from 'rxjs';
import { DaterangepickerConfig, DaterangePickerComponent } from 'ng2-daterangepicker';

@Component({
  selector: 'app-main-collecte-donne',
  templateUrl: './main-collecte-donne.component.html',
  styleUrls: ['./main-collecte-donne.component.scss']
})
export class MainCollecteDonneComponent implements OnInit {

  results: Array<any> = [];
  ListeRoles: Array<any> = [];
  ListeCartes: Array<any> = [];
  busyGet: Subscription;
  busySave: Subscription;
  itemToSave: any;
  totalItems = 0;
  currentPage = 1;
  itemPerPageSize: number;
  user: any;
  itemsPerPage: any;
  currentItemChecked: any;
  statutCotisation: any;
  itemToSearch: any = {};
  oldValueSearch: String = "";
  // modalRef: BsModalRef;
  isChangeItermPerPage: boolean;
  daterange: any = {};
  config = {
    animated: true,
    keyboard: false,
    backdrop: 'static',
    ignoreBackdropClick: true
  };

  @ViewChild(DaterangePickerComponent) private picker: DaterangePickerComponent;

  constructor(private api: ApiService, public router: Router, private utilities: UtilitiesService, private userService: UserService, private notificationService: NotificationService, private daterangepickerOptions: DaterangepickerConfig) {

    this.user = this.userService.getCurrentUser();
    if (!this.user) {
      this.router.navigate(['/auth']);
    }

    this.itemsPerPage = 15;
    this.itemPerPageSize = 5;

    this.itemToSearch = {
      dateDebut: moment().subtract(6, 'day').format("DD/MM/YYYY"),
      dateFin: moment(new Date()).format("DD/MM/YYYY"),
    };

    this.daterangepickerOptions.settings = {
      locale: {
        format: 'DD-MM-YYYY',
        cancelLabel: 'Annuler',
        applyLabel: 'Rechercher',

        "customRangeLabel": "Personnaliser",
        "daysOfWeek": [
          "Dim",
          "Lun",
          "Mar",
          "Mer",
          "Jeu",
          "Ven",
          "Sam"
        ],
        "monthNames": [
          "Janvier",
          "Fevrier",
          "Mars",
          "Avril",
          "Mai",
          "Juin",
          "Juillet",
          "Aout",
          "Septembre",
          "Octobre",
          "Novembre",
          "Decembre"
        ],
      },
      alwaysShowCalendars: false,
      ranges: {
        "Aujourd'hui": moment(),
        "La journée d'hier": [moment().subtract(1, 'day'), moment().subtract(1, 'day')],
        'Les 03 derniers jours': [moment().subtract(2, 'day'), moment()],
        'Les 07 derniers jours': [moment().subtract(6, 'day'), moment()],
        'Les 15 derniers jours': [moment().subtract(14, 'day'), moment()],
        'Les 30 derniers jours': [moment().subtract(29, 'day'), moment()],
        'Les 60 derniers jours': [moment().subtract(59, 'day'), moment()],
        'Les 90 derniers jours': [moment().subtract(89, 'day'), moment()],
      }
    };
  }

  reinitSeachPeriode() {
    this.picker.datePicker.setStartDate(this.itemToSearch.dateDebut);
    this.picker.datePicker.setEndDate(this.itemToSearch.dateFin);
  }

  selectedDate(value: any, datepicker?: any) {

    // any object can be passed to the selected event and it will be passed back here
    datepicker.start = value.start;
    datepicker.end = value.end;

    // or manupulat your own internal property
    this.itemToSearch.dateDebut = moment(value.start).format('DD/MM/YYYY');
    this.itemToSearch.dateFin = moment(value.end).format('DD/MM/YYYY');

    this.daterange.label = value.label;

    this.getData();
  }

  pageChanged(event: any): void {
    console.log('Page changed to: ' + event.page);
    console.log('Number items per page: ' + event.itemsPerPage);
    this.currentPage = event.page;
    this.isChangeItermPerPage = true;
    this.oldValueSearch = "";
    this.getData();
  }

  changeItermPerPage() {
    this.currentPage = 1;
    this.getData();
  }

  getData() {

    let search_value = this.itemToSearch.fullText;
    let isSearch = this.itemToSearch.fullText ? true : false;

    if (search_value && this.oldValueSearch) {
      if (search_value != this.oldValueSearch) {
        this.currentPage = 1;
      }
    }

    if (search_value) {
      search_value = search_value.trim();

      if (search_value.length > 0 && search_value.length < 3) {
        if (!this.isChangeItermPerPage) {
          return;
        }
      }
      else {
        if (search_value == this.oldValueSearch) {
          return;
        }
        this.oldValueSearch = search_value;
      }
    }
    else {
      if (this.oldValueSearch == "" && isSearch) {
        return;
      }
      this.oldValueSearch = "";
    }

    var request = {
      user: this.user.proxy,
      data: {
        libelle: search_value || null,
        localite: (this.user.centre && this.user.centre.localite) ? this.user.centre.localite : null,
        dateStatistique: null,
        dateStatistiqueParam: {}
      },
      index: (this.currentPage - 1),
      size: this.itemsPerPage ? this.itemsPerPage : null
    }

    if (this.itemToSearch.dateDebut && this.itemToSearch.dateFin && this.itemToSearch.dateDebut != null && this.itemToSearch.dateFin != null) {
      request.data.dateStatistique = this.itemToSearch.dateDebut.toLocaleString().split(' ')[0] + ' 00:00:00';
      request.data.dateStatistiqueParam =
        {
          "operator": "[]",
          "start": this.itemToSearch.dateDebut.toLocaleString().split(' ')[0] + ' 00:00:00', //"22/02/2019",
          "end": this.itemToSearch.dateFin.toLocaleString().split(' ')[0] + ' 23:59:59' //"22/02/2019"
        }
    }

    this.busyGet = this.api.post('statistiquesOneci/getByCriteria', request)
      .subscribe(
        res => {
          console.log(res);
          if (res && res['items']) {
            this.results = res['items'];
            this.totalItems = res['count'] ? res['count'] : 0;
            this.isChangeItermPerPage = false;
          } else {
            this.results = [];
          }
        },
        err => {
          console.log("Error occured", err);
          this.notificationService.showMessageError("Conexion", this.utilities.defautMessage());
        }
      );
  }

  getExactlyNumberRow(page, index) {
    let num = index + 1;
    if (page > 1) {
      num = ((page - 1) * 15) + (index + 1);
    }
    return num;
  }

  ngOnInit() {
    this.getData();
    window.scrollTo(0, 0);
  }

  ngAfterViewInit() {
    this.reinitSeachPeriode(); 
  }


}
