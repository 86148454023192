import { Injectable } from '@angular/core';
import { UtilitiesService } from './utilities.service';
// const swal = require('sweetalert2');
import swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private utilities : UtilitiesService) { }

  showMessageError(title,message)
  {
        swal({
            title: title,
            text: message,
            type: 'info',
            showCancelButton: false,
            confirmButtonText: 'Ok',
            cancelButtonText: 'Non',
            confirmButtonColor: "#f29302",
            cancelButtonColor: "#333333",
        }).then((result) => {
            if (result) {

            } else {
              console.log("bnjr");
            }
        })

  }

  showMessageSucces(title,message)
  {
    swal({
      title: title,
      text: message,
      type: 'info',
      showCancelButton: false,
      confirmButtonText: 'Ok',
      cancelButtonText: 'Non',
      confirmButtonColor: "#f29302",
      cancelButtonColor: "#333333",
    }).then((result) => {
        if (result) {

        } else {
          console.log("bnjr");
        }
    })

  }
}
