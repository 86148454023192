import { Component, OnInit } from '@angular/core';
import { FormReconnectComponent } from '../../form-reconnect/form-reconnect.component';
import { ActivatedRoute, Router } from '@angular/router';

import { UtilitiesService } from '../../services/utilities.service';
import { UserService } from '../../services/user.service';
import { NotificationService } from '../../services/notification.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-auth-layout',
  templateUrl: './auth-layout.component.html',
  styleUrls: ['./auth-layout.component.scss']
})
export class AuthLayoutComponent implements OnInit {

  user : any;
  deconnectionTimer : number;
  bsModalRef : BsModalRef;
  intervalle = 1000;
  countTer = 0;
  activite_detectee : boolean; 
  logoutModalIsLunch : boolean = false;
  lunchFonction : any;

  config = {
    animated: true,
    keyboard: false,
    backdrop: 'static',
    ignoreBackdropClick: true
  };


  constructor(private activedRoute: ActivatedRoute,private userService: UserService,public router:Router,private api:ApiService,private modalService: BsModalService) {
   
    this.user = this.userService.getCurrentUser(); 
  }


  ngOnInit() {
    
  }

  ngOnDestroy(){
    window.clearTimeout(this.lunchFonction);
  }

}
