import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainLayoutComponent } from './shared/main-layout/main-layout.component';
import { HeaderComponent } from './shared/header/header.component';
import { FooterComponent } from './shared/footer/footer.component';
import { HomeComponent } from './home/home.component';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { AuthLayoutComponent } from './shared/auth-layout/auth-layout.component';
import { MainAuthentificationComponent } from './main-authentification/main-authentification.component';
import { FormReinitPasswordComponent } from './form-reinit-password/form-reinit-password.component';
import { HttpClientModule } from '@angular/common/http';
import { ModalModule } from 'ngx-bootstrap/modal';
import { FormCollecteComponent } from './form-collecte/form-collecte.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxMaskModule} from 'ngx-mask';
import { MainCollecteDonneComponent } from './main-collecte-donne/main-collecte-donne.component';
import { PaginationModule } from 'ngx-bootstrap/pagination';
// import {NgBusyModule} from 'ng-busy';
import {BusyModule} from 'angular2-busy';
import { FormReconnectComponent } from './form-reconnect/form-reconnect.component';
import { Daterangepicker } from "ng2-daterangepicker";

@NgModule({
  declarations: [
    AppComponent,
    MainLayoutComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    AuthLayoutComponent,
    MainAuthentificationComponent,
    FormReinitPasswordComponent,
    FormCollecteComponent,
    MainCollecteDonneComponent,
    FormReconnectComponent
   
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    CarouselModule.forRoot(),
    BsDatepickerModule.forRoot(),
    ModalModule.forRoot(),
    NgxMaskModule.forRoot(),
    PaginationModule.forRoot(),
    
    BusyModule,
    Daterangepicker
  ],
  providers: [],
  bootstrap: [AppComponent],
  entryComponents: [FormReinitPasswordComponent,FormReconnectComponent],
})
export class AppModule { }
