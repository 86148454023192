import { Injectable } from '@angular/core';
// import { RequestOptions, URLSearchParams } from '@angular/http';
// import 'rxjs/add/operator/map';
import {HttpClient, HttpParams} from "@angular/common/http";
import {UserService} from "./user.service";

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  url: any;
  user :any;
  constructor(public http: HttpClient,private userService: UserService) {
       this.user = this.userService.getCurrentUser(); 
       this.url =  'https://collecte.oneci.ci/api/arep/'; //'https://oneci-arep-api-dev.smile.ci/arep'; 

  }


  get(endpoint: string, params?: any, options?: HttpParams) {

      return this.http.get(this.url + '/' + endpoint);
  }

  getOption(endpoint: string, params?: any, options?: any) {

      return this.http.get(this.url + '/' + endpoint,options);
  }

  postOption(endpoint: string, body: any, options: any) {
      return this.http.post(this.url + '/' + endpoint, body,options);
  }

  post(endpoint: string, body: any, options?: any) {
      console.log(this.url + '/' + endpoint);
    
      return this.http.post(this.url + '/' + endpoint, body);
  }

  postTest(endpoint: string, body: any, options?: any) {
      console.log(this.url + '/' + endpoint);
    
      return this.http.post('http://e-tiers-api.swanpro.net/ispc-backend' + '/' + endpoint, body);
  }

  put(endpoint: string, body: any, options?: any) {
      return this.http.put(this.url + '/' + endpoint, body);
  }

  delete(endpoint: string, options?: any) {
      return this.http.delete(this.url + '/' + endpoint);
  }

  patch(endpoint: string, body: any, options?: any) {
      return this.http.put(this.url + '/' + endpoint, body);
  }
}
