import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/user.service';
import { Router } from '@angular/router';
import swal from 'sweetalert2';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  user: any;

  constructor(private userService: UserService,public router:Router) {
    this.user = this.userService.getCurrentUser();
  }

  ngOnInit() {
  }

  truncateValue(item: any, nbre) {
    if (!item) return;

    let regex = /(&nbsp;|<([^>]+)>)/ig;

    //RETIRER LES CARACTERES HTML
    item = item.replace(regex, "");

    if (item.length > nbre) {
      item = item.substring(0, nbre).trim() + "" + " ...";
    }
    return item;
  }

  getIdentiteUser() {
    return this.truncateValue(this.user.nom + ' ' + this.user.prenoms, 12);
  }

  logout()
  {
    swal({
      title: 'Déconnexion',
      text: "<span style='font-size:17px'> Voulez-vous vous déconnecter de l\'application ? </span>",
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      confirmButtonColor: "#ff7512",
      cancelButtonColor: "rgb(154, 154, 153)",
    }).then((result) => {
      if (result) {
        localStorage.removeItem("yz-backoffice-app-collecte-oneci");
        this.router.navigate(['/auth']);
      } else {
        // console.log("bnjr");
      }
    })

  }
}
