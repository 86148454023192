import { Component, OnInit } from '@angular/core';
import { FormReinitPasswordComponent } from '../form-reinit-password/form-reinit-password.component';
// import { Subscription } from 'rxjs';
import { UtilitiesService } from '../services/utilities.service';
import { Router } from '@angular/router';
import { ApiService } from '../services/api.service';

import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { NotificationService } from '../services/notification.service';

@Component({
  selector: 'app-main-authentification',
  templateUrl: './main-authentification.component.html',
  styleUrls: ['./main-authentification.component.scss']
})
export class MainAuthentificationComponent implements OnInit {

  currentPositionGps : any = {};
  itemToSave : any = {};
  bsModalRef: BsModalRef;
  busyConnecte : any; // Subscription;

  config = {
    animated: true,
    keyboard: false,
    backdrop: 'static',
    ignoreBackdropClick: true
  };

  constructor(private api:ApiService,public router:Router,private modalService: BsModalService,private utilities : UtilitiesService,private notificationService : NotificationService) {
       
    this.itemToSave = {};
  }

  ngOnInit() {
    this.getPositionGps(); 
  }


  openChangePassword(user) {
    const initialState = {
      title : "Changer votre mot de passe.",
      config : this.config
    };
    this.bsModalRef = this.modalService.show(FormReinitPasswordComponent, Object.assign({}, this.config, { class: 'gray modal-sm modal-custom-reinit' }));
    this.bsModalRef.content.title = 'Changer votre mot de passe';
    this.bsModalRef.content.currentUser = user;
  }

  login(item)
  {
    console.log("le item",item);
    item.showLoader = true;
    if(!item  || !item.login || !item.password)
    {
      item.showLoader = false;
      this.notificationService.showMessageError("Erreur","Veuillez renseigner les champs obligatoires.");
      return;
    }
    
    var request = {
      user : "1",
      "data":
        {
          "login": item.login,
          "password": item.password,
          source : "Web",
          referenceSource : "Web",
          positionGps : this.currentPositionGps && this.currentPositionGps.positionGps ? this.currentPositionGps.positionGps : null
        } 
    }

     this.busyConnecte = this.api.post('user/login', request)
          .subscribe(
              res => {
                  console.log('get user',res); 
                  item.showLoader = false;
                  if(!res['hasError'] && res['items'])
                  {

                    var currentUser = res['items'][0];
                    currentUser.Password = null;

                    if(currentUser.resetPassword)
                    {
                      this.itemToSave = {};
                      currentUser.isFromLogin = true;
                      localStorage.setItem("yz-backoffice-app-collecte-oneci",JSON.stringify(currentUser));
                      this.openChangePassword(currentUser);
                      return;
                    }

                    localStorage.setItem("yz-backoffice-app-collecte-oneci",JSON.stringify(currentUser));
                    this.router.navigate(['/collecte-donnee']);
                  }
                  else
                  {
                    this.notificationService.showMessageError("Connexion",this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
                  }
              },
              err => {
                  console.log("Error occured G",err);
                  item.showLoader = false;
              }
          );
  }

  motDePassOublie(item)
  {

    console.log("le item",item);
    item.showLoader = true;
    if(!item  || !item.libellePassForget)
    {
      item.showLoader = false;
      this.notificationService.showMessageError("Erreur","Veuillez renseigner le Login / email / Contact.");
      return;
    }
    
    var request = {
      //user : "1",
      "datas":[
        {
          "login": item.libellePassForget 
          } 
       ]
      
    }

     this.busyConnecte = this.api.post('user/motDePasseOublie', request)
          .subscribe(
              res => {
                  console.log('get user',res); 
                  item.showLoader = false;
                  if(!res['hasError'] && res['items'])
                  {
                    this.itemToSave.message = this.utilities.mainSelfFormatMsgServeur(res['status']['message']);
                    this.notificationService.showMessageSucces("Mot de passe oublié",this.itemToSave.message);

                    setTimeout(() => {
                      this.updatePassWord();
                    }, 10000);
                    
                    
                  }
                  else
                  {
                    this.itemToSave.message = this.utilities.mainSelfFormatMsgServeur(res['status']['message']);
                    this.notificationService.showMessageError("Connexion",res['status'].message);
                  }
              },
              err => {
                  console.log("Error occured G",err);
                  item.showLoader = false;
              }
          );
  }

  updatePassWord()
  {
     this.itemToSave.isPasswordForget = !this.itemToSave.isPasswordForget;
  }

  getPositionGps() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        console.log("Geolocalisation ",position);

        if(position && position.coords)
        {
          this.currentPositionGps.positionGps = position.coords.latitude +","+position.coords.longitude;
        }
        //this.showPosition(position);
      });
    } else {
      alert("Geolocation non supporté par votre navigateur.");
    }
  }

}
