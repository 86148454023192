import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilitiesService {

  constructor() { }

  defautMessage()
  {
    var messageDefault = "Service momentanément interrompu";
    return messageDefault;
  }

  mainSelfFormatMsgServeur(msg) {
    console.log("le message", msg);
    var messageError = msg;
    if (msg) {

      if (msg.trim() == "Operation effectuee avec succes:") {
        messageError = messageError.replace("Operation effectuee avec succes:", "Operation effectuée avec succès.");
      }
      else {
        messageError = messageError.replace("Operation effectuee avec succes:", "");
      }

      messageError = messageError.replace("Operation effectuee avec succes:", "Operation effectuée avec succès");
      messageError = messageError.replace("Impossible de se connecter a la base de donnees:", "");
      messageError = messageError.replace("La base de donnees est indisponible:", "");
      messageError = messageError.replace("Permission refusee par la base de donnees:", "");
      messageError = messageError.replace("Le serveur de base de donnees a refuse la requete:", "");
      messageError = messageError.replace("Authentification echouee:", "");
      messageError = messageError.replace("Donnee existante:", "");
      messageError = messageError.replace("Liste vide : il n'y a pas de donnees respectant ce critere:", "");
      messageError = messageError.replace("il n'y a pas de donnees respectant ce critere", "");
      messageError = messageError.replace("Champ non renseigne:", "");
      messageError = messageError.replace("Utilisateur deja connecte:", "");
      messageError = messageError.replace("la requete attendue n'est pas celle fournie:", "");
      messageError = messageError.replace("Le type est incorrect:", "");
      messageError = messageError.replace("Le format de la date est incorrect:", "");
      messageError = messageError.replace("le serveur a signale un format invalide:", "");
      messageError = messageError.replace("le code de la langue n'est pas valide:", "");
      messageError = messageError.replace("La periode de date n'est pas valide", "");
      messageError = messageError.replace("une erreur est survenue lors de l'enregistrement:", "");
      messageError = messageError.replace("le name de l'entite n'est pas valide:", "");
      messageError = messageError.replace("Veuillez renseigner une seule valeur pour cette donnee:", "");
      messageError = messageError.replace("La somme des pourcentages ne doit exceder 100:", "");
      messageError = messageError.replace("Erreur de generation de fichier:", "");
      messageError = messageError.replace("login et/ou mot de passe incorrect(s):", "");
      messageError = messageError.replace("Operation interdite/refusee:", "");
      messageError = messageError.replace("Ccette donnees ne peut etre supprimee car elle est utilisee:", "");
      messageError = messageError.replace("cette donnees est trop superieure:", "");
      messageError = messageError.replace("Vous n'etes pas autoriser a effectuer cette operation.", "");
      messageError = messageError.replace("Donnee inexistante:", "");
      messageError = messageError.replace("Erreur interne:", "");
      messageError = messageError.replace("Le serveur de base de donnees a refuse la requete:", "");
      messageError = messageError.replace("cette donnees ne peut etre supprimee car elle est utilisee:", "");
      messageError = messageError.replace("Vous n'etes pas autoriser a effectuer cette operation.", "");


    }
    return messageError;
  };


}
