import { Component, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
// import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
// import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { Subscription } from 'rxjs';
import { ApiService } from '../services/api.service';
import { Router } from '@angular/router';
import { UtilitiesService } from '../services/utilities.service';
import { UserService } from '../services/user.service';
import { NotificationService } from '../services/notification.service';
import { BsModalRef } from 'ngx-bootstrap';
@Component({
  selector: 'app-form-reinit-password',
  templateUrl: './form-reinit-password.component.html',
  styleUrls: ['./form-reinit-password.component.scss']
})
export class FormReinitPasswordComponent implements OnInit {

  itemToSave : any;
  user : any;
  // busySave : Subscription;
  // bsModalRef: BsModalRef;
  currentUser : any = {};

  constructor(public bsModalRef: BsModalRef,private api:ApiService,public router:Router,private modalService: BsModalService,private utilities : UtilitiesService,private userService : UserService,private notificationService : NotificationService) {
    this.itemToSave = {};
    this.user = this.userService.getCurrentUser();
   }

   saveItem(item)
   {
       if(!item || !item.password || !item.newpassword)
       {
           this.notificationService.showMessageError("Information","Veuillez renseigner l'ancien et le nouveau mot de passe.")
           return;
       }
   
       if(item.ConfirmNewPassword != item.newpassword)
       {
         this.notificationService.showMessageError("Information","Veuillez confirmer votre mot de passe.")
         return;
       }
   
       item.proxy = this.itemToSave.proxy  || this.user.proxy;
       this.itemToSave.showLoader = true;
       
       var request = {
         user : this.itemToSave.proxy || this.user.proxy,
         "datas" : [item]
       }
   
       this.api.post('user/changePassword', request)
           .subscribe(
               res => {
                 console.log("resul",res);
                   if(!res['hasError'])
                   {
                       this.itemToSave.showLoader = false;
                       this.notificationService.showMessageSucces("Mot de passe",this.utilities.mainSelfFormatMsgServeur(res['status']['message']));
                       // on le deconnecte
                       this.bsModalRef.hide();
                       
                       localStorage.removeItem("yz-backoffice-app-collecte-oneci");
                       var currentUser = res['items'][0];
                       //localStorage.setItem("yz-backoffice-app-boutique-shell",JSON.stringify(currentUser));
   
                       if(currentUser && currentUser.fonctionnalites && currentUser.fonctionnalites.length > 0)
                       {
                         currentUser.fonctionnalites.push({code : "DASH",libelle : 'DASHBORD'});
                       }
                       
                       localStorage.setItem("yz-backoffice-app-collecte-oneci",JSON.stringify(currentUser));
                       
                       if(this.currentUser.isFromLogin)
                       {
                         this.router.navigate(['/collecte-donnee']);
                       }
                       else
                       {
                         //this.router.navigate(['/auth/login']);
                       }
   
                   }else
                   {
                     if(res['status'] && res['status']['message'])
                     {
                       this.notificationService.showMessageError("Erreur",this.utilities.mainSelfFormatMsgServeur(res['status']['message'])); 
                     }
                   }
               },
               err => {
                   console.log("Error occured",err);
                   //this.notificationService.showMessageError("Erreur",this.utilities.messageInterpretor(err));
               }
           );
   }
   

  ngOnInit() {
  }

}
